import React from 'react'
import { useSelector } from 'react-redux';

const UserAvatar = ({size, name}) => {

    const userName = useSelector(state => state?.user?.name);

  return (
    <div style={{
        width:size,
        height: size,
        borderRadius:'50%',
        display :'flex',
        justifyContent :'center',
        alignItems:'center',
        color: '#fff',
        fontSize:  size > 36 ? 20 : 14,
        background: '#4f92fe'
      }}>
        {name ? name?.[0] : userName?.[0] ?? 'T'}
        </div>
  )
}

export default UserAvatar