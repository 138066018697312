import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  actions: [],
  name: '',
  id: '',
  profile_img: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addActions(state, action) {
      state.actions = action.payload?.actions ?? [];
      state.name = action.payload?.name ?? ''
      state.id = action.payload?.id
    },
    addName(state, action) {
      state.name = action.payload?.name ?? ''
    },
    bindPofileImg(state, action) {
      state.profile_img = action.payload ?? ''
    },
    resetState(state, action) {
      state = initialState;

    }
  },
})

export const { addActions,bindPofileImg, resetState, addName } = userSlice.actions;

export default userSlice.reducer