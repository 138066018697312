import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { socket } from '../App';
import TokenService from '../Services/token.service';
import UserService from '../Services/user.service';
import { addActions } from '../Store/features/user.slice';

const PrivateRoute = ({ component: Component, actionName, actions, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = TokenService.getLocalAccessToken();
    useEffect(() => {
        if (currentUser && !actions?.length) {
            userinfo()
        }
    }, [])

    const userinfo = async () => {
        try {
            const res = await UserService.getUserinfo();
            console.log(res?.data)
            if (res?.data) {
                socket.emit('user', { id: res.data.id, isCorporate: res?.data?.actions?.includes('Corporate view') })
                dispatch(addActions({ actions: res?.data?.actions || [], id: res.data.id, name: res?.data?.name}))
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (!currentUser) {
        TokenService.removeUser();
        return <Navigate to={{ pathname: '/home', state: { from: rest.location } }} />
    }
    if (actions.length && (Array.isArray(actionName) && !actionName?.some(q => actions?.includes(q))) || (!Array.isArray(actionName) && !actions.includes(actionName))) {
        // TokenService.removeUser();
        return <Navigate to={{ pathname: '/home' }} />
    } else {
        return (<Component {...rest} />)
    }


}

export default PrivateRoute;