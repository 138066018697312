import React from 'react'

const CorporateAvatar = ({size}) => {

  return (
         <img src='/assets/img/corporateq.png' style={{
          height: size
         }} />
  )
}

export default CorporateAvatar