import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const DashboardLayout = lazy(() => import('../Modules/Dashboard/Dashboard'));
const LoginLayout = lazy(() => import('../Modules/Auth/LoginScreen/LoginScreen'));
const SignupLayout = lazy(() => import('../Modules/Auth/SignupScreen/SignupScreen'));
const MemberListLayout = lazy(() => import('../Modules/Dashboard/Admin/MemberList/MemberList'));
const UsersListLayout = lazy(() => import('../Modules/Dashboard/Admin/UsersList/UsersList'));
const RoleListLayout = lazy(() => import('../Modules/Dashboard/Admin/RolesManagement/RoleList'));
const AddRoleLayout = lazy(() => import('../Modules/Dashboard/Admin/RolesManagement/AddRole/AddRole'));
const AddMemberLayout = lazy(() => import('../Modules/Dashboard/Admin/MemberList/AddMember/AddMember'));
const ApprovalListLayout = lazy(() => import('../Modules/Dashboard/Admin/ApprovalList/ApprovalList'));
const ReportsListLayout = lazy(() => import('../Modules/Dashboard/Admin/ReportsList/ReportsList'));
const AssignedUserListLayout = lazy(() => import('../Modules/Dashboard/Admin/AssignedUserList/AssignedUserList'));
const AddProfileLayout = lazy(() => import('../Modules/Dashboard/Admin/AddProfile/AddProfile'));
const CorporateApprovalListLayout = lazy(() => import('../Modules/Dashboard/Admin/CorporateApprovalList/CorporateApprovalList'));
const AssignedCorporateListLayout = lazy(() => import('../Modules/Dashboard/Admin/AssignedCorporateList/AssignedCorporateList'));
const CorporateListLayout = lazy(() => import('../Modules/Dashboard/Admin/CorporateList/CorporateList'));
const AddCorporateProfileLayout = lazy(() => import('../Modules/Dashboard/Admin/AddCorporateProfile/AddCorporateProfile'));
const ClubHouseLayout = lazy(() => import('../Modules/Dashboard/User/ClubHouse/ClubHouse'));
const HomeLayout = lazy(() => import('../Modules/Dashboard/User/ClubHouse/Home/Home'));
const ProfileLayout = lazy(() => import('../Modules/Dashboard/User/ClubHouse/Profile/Profile'));
const ConnectionsLayout = lazy(() => import('../Modules/Dashboard/User/Connections/Connections'));
const JobsLayout = lazy(() => import('../Modules/Dashboard/User/Jobs/Jobs'));
const SavedItemsLayout = lazy(() => import('../Modules/Dashboard/User/SavedItems/SavedItems'));
const MyPostsLayout = lazy(() => import('../Modules/Dashboard/User/MyPosts/MyPosts'));
const CorporateLayout = lazy(() => import('../Modules/Dashboard/Corporate/Corporate'));
// const PostJobLayout = lazy(() => import('../Modules/Dashboard/Corporate/PostJob/PostJob'));
const ManageJobsLayout = lazy(() => import('../Modules/Dashboard/Corporate/ManageJobs/ManageJobs'));
const JobInfoLayout = lazy(() => import('../Modules/Dashboard/User/JobInfo/JobInfo'));
// const CompanyInfoLayout = lazy(() => import('../Modules/Dashboard/User/CompanyInfo/CompanyInfo'));
const CorporateInfoLayout = lazy(() => import('../Modules/Dashboard/User/CorporateInfo/CorporateInfo'));
const CandidateInfoLayout = lazy(() => import('../Modules/Dashboard/User/CandidateInfo/CandidateInfo'));
const MessagesLayout = lazy(() => import('../Modules/Dashboard/User/Chat/Chat'));
const AppliedJobsLayout = lazy(() => import('../Modules/Dashboard/Corporate/AppliedJobs/AppliedJobs'));
const AppliedJobsByCandidateLayout = lazy(() => import('../Modules/Dashboard/User/AppliedJobs/AppliedJobs'));
const AddCXOLayout = lazy(() => import('../Modules/Dashboard/Admin/AddCXO/AddCXO'));
const AddCorporateLayout = lazy(() => import('../Modules/Dashboard/Admin/AddCorporate/AddCorporate'));
const AddPromotionLayout = lazy(() => import('../Modules/Dashboard/Admin/AddPromotion/AddPromotion'));
const PromotionApprovalsLayout = lazy(() => import('../Modules/Dashboard/Admin/PromotionApprovals/PromotionApprovals'));
const AddPostLayout = lazy(() => import('../Modules/Dashboard/Admin/AddPost/AddPost'));
const CorporateProfileLayout = lazy(() => import('../Modules/Dashboard/Corporate/CorporateProfile/CorporateProfile'));
const SearchLayout = lazy(() => import('../Modules/Dashboard/User/Search/Search'));
const NotificationsLayout = lazy(() => import('../Modules/Dashboard/User/Notifications/Notifications'));
const AddPartnerLayout = lazy(() => import('../Modules/Dashboard/Admin/AddPartner/AddPartner'));
const PartnerApprovalsLayout = lazy(() => import('../Modules/Dashboard/Admin/PartnerApprovals/PartnerApprovals'));
const AddPackageLayout = lazy(() => import('../Modules/Dashboard/Admin/AddPackage/AddPackage'));
const PackageListLayout = lazy(() => import('../Modules/Dashboard/Admin/PackageList/PackageList'));
const OverviewLayout = lazy(() => import('../Modules/Dashboard/Admin/Overview/view/app-view'));
const CorporateSearchLayout = lazy(() => import('../Modules/Dashboard/Corporate/SearchUsers/SearchUsers'));
const JobPostingLayout = lazy(() => import('../Modules/Dashboard/User/JobPosting/JobPosting'));
const PostedJobsLayout = lazy(() => import('../Modules/Dashboard/User/JobPosting/PostedJobs/PostedJobs'));
const PostJobLayout = lazy(() => import('../Modules/Dashboard/User/JobPosting/PostJob/PostJob'));
const AddFaqLayout = lazy(() => import('../Modules/Dashboard/Admin/AddFaq/AddFaq'));
const TrendingNewsLayout = lazy(() => import('../Modules/Dashboard/Admin/TrendingNews/TrendingNews'));
const TrendingNewsListLayout = lazy(() => import('../Modules/Dashboard/Admin/TrendingNewsList/TrendingNewsList'));
const FaqListLayout = lazy(() => import('../Modules/Dashboard/Admin/FaqList/FaqList'));
const StoryLayout = lazy(() => import('../Modules/Dashboard/User/Story/Story'));

// Portfolio
const LandingLayout = lazy(() => import('../Modules/Portfolio/Home/Home'));
const AboutLayout = lazy(() => import('../Modules/Portfolio/About/About'));
const ResourcesLayout = lazy(() => import('../Modules/Portfolio/Resources/Resources'));
const CareerLayout = lazy(() => import('../Modules/Portfolio/Career/Career'));
const FaqsLayout = lazy(() => import('../Modules/Portfolio/Faqs/Faqs'));
const PrivacyLayout = lazy(() => import('../Modules/Portfolio/PrivacyPol/privacy'));
const TermsLayout = lazy(() => import('../Modules/Portfolio/TermsConditions/Terms'));
const PricingLayout = lazy(() => import('../Modules/Portfolio/Pricing/Pricing'));

export const routes = ({ actions, showSuccess }) => (
    [
        {
            path: '/auth',
            element: (<Outlet />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/login" />
                },
                {
                    path: 'login',
                    element: <LoginLayout showSuccess={showSuccess} />
                },
                {
                    path: 'signup',
                    element: <SignupLayout />
                }
            ]
        },
        {
            path: '/clubhouse',
            element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={ClubHouseLayout} />),
            children: [
                {
                    index: true,
                    element: <Navigate to="home" />
                },
                {
                    path: 'about',
                    element: (<AboutLayout loggedIn={true} />)
                },
                {
                    path: 'terms',
                    element: <TermsLayout loggedIn={true} />
                },
                {
                    path: 'privacy',
                    element: <PrivacyLayout loggedIn={true} />
                },
                {
                    path: 'home',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={HomeLayout} />)
                },
                {
                    path: 'story/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={StoryLayout} />)
                },

                {
                    path: 'home/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={HomeLayout} />)
                },
                {
                    path: 'profile',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={ProfileLayout} />)
                },
                {
                    path: 'connections',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage view']} actions={actions} component={ConnectionsLayout} />)
                },
                {
                    path: 'jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage view']} actions={actions} component={JobsLayout} />)
                },
                {
                    path: 'saved-items',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage view', 'Corporate view']} actions={actions} component={SavedItemsLayout} />)
                },
                {
                    path: 'my-posts',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage view', 'Corporate view']} actions={actions} component={MyPostsLayout} />)
                },
                {
                    path: 'job/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={JobInfoLayout} />)
                },
                {
                    path: 'company/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={CorporateInfoLayout} />)
                },
                {
                    path: 'candidate/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={CandidateInfoLayout} />)
                },
                {
                    path: 'messages',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={MessagesLayout} />)
                },
                {
                    path: 'applied-jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage view']} actions={actions} component={AppliedJobsByCandidateLayout} />)
                },
                {
                    path: 'search',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={SearchLayout} />)
                },
                {
                    path: 'notifications',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view', 'Manage view']} actions={actions} component={NotificationsLayout} />)
                },
                {
                    path: 'my-jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={JobPostingLayout} />),
                    children: [
                        {
                            index: true,
                            element: <Navigate to="open-jobs" />
                        },
                        {
                            path: 'open-jobs',
                            element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={PostedJobsLayout} />)
                        },
                        {
                            path: 'closed-jobs',
                            element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={PostedJobsLayout} />)
                        },
                        {
                            path: 'post-job',
                            element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={PostJobLayout} />)
                        },
                    ]
                },
            ]
        },
        {
            path: '/corporate',
            element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={CorporateLayout} />),
            children: [
                {
                    path: 'manage-jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={ManageJobsLayout} />)
                },
                {
                    path: 'applied-jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={AppliedJobsLayout} />)
                },
                {
                    path: 'post-job',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={PostJobLayout} />)
                },
                {
                    path: 'profile',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={CorporateProfileLayout} />)
                },
                {
                    path: 'connections',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={ConnectionsLayout} />)
                },
                {
                    path: 'jobs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={JobsLayout} />)
                },
                {
                    path: 'job/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={JobInfoLayout} />)
                },
                {
                    path: 'company/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={CorporateInfoLayout} />)
                },

                {
                    path: 'candidate/:id',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={CandidateInfoLayout} />)
                },
                {
                    path: 'messages',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={MessagesLayout} />)
                },
                {
                    path: 'search',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate view']} actions={actions} component={CorporateSearchLayout} />)
                },
            ]
        },
        {
            path: '/home',
            element: (<LandingLayout />)
        },
        {
            path: '/about',
            element: (<AboutLayout />)
        },
        {
            path: '/guidelines',
            element: (<ResourcesLayout />)
        },
        {
            path: '/careers',
            element: (<CareerLayout />)
        },
        {
            path: '/faqs',
            element: (<FaqsLayout />)
        },
        {
            path: 'terms',
            element: <TermsLayout />
        },
        {
            path: 'privacy',
            element: <PrivacyLayout />
        },

        {
            path: '/pricing',
            element: (<PricingLayout />)
        },


        {
            path: '/',
            element: actions.length > 0 ? actions.includes('Manage view') ? <Navigate to="/clubhouse" /> : actions.includes('Corporate view') ? <Navigate to="/corporate" /> : (<DashboardLayout />) : <Navigate to="/home" />,
            children: [
                {
                    path: 'users',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['User Management']} actions={actions} component={UsersListLayout} />)
                },
                {
                    path: 'roles',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Roles Management']} actions={actions} component={RoleListLayout} />)
                },
                {
                    path: 'add-role',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Roles Management']} actions={actions} component={AddRoleLayout} />)
                },
                {
                    path: 'members',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Members Management']} actions={actions} component={MemberListLayout} />)
                },
                {
                    path: 'add-member',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Members Management']} actions={actions} component={AddMemberLayout} />)
                },
                {
                    path: 'approvals',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Approve the account and send notifications']} actions={actions} component={ApprovalListLayout} />)
                },
                {
                    path: 'reports',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Reports Management']} actions={actions} component={ReportsListLayout} />)
                },
                
                {
                    path: 'add-cxo',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Approve the account and send notifications']} actions={actions} component={AddCXOLayout} />)
                },
                {
                    path: 'add-corporate',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Approve the account and send notifications']} actions={actions} component={AddCorporateLayout} />)
                },
                {
                    path: 'assigned-users',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['CXO Professional Profile Creation']} actions={actions} component={AssignedUserListLayout} />)
                },
                {
                    path: 'corporate-approvals',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Approve the account and send notifications']} actions={actions} component={CorporateApprovalListLayout} />)
                },
                {
                    path: 'assigned-corporates',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate Profile Creation']} actions={actions} component={AssignedCorporateListLayout} />)
                },
                {
                    path: 'add-corporate-profile',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Corporate Profile Creation'} actions={actions} component={AddCorporateProfileLayout} />)
                },
                {
                    path: 'corporates',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Corporate Management']} actions={actions} component={CorporateListLayout} />)
                },
                {
                    path: 'add-promotion',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage promotions']} actions={actions} component={AddPromotionLayout} />)
                },
                {
                    path: 'add-post',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage post']} actions={actions} component={AddPostLayout} />)
                },
                {
                    path: 'add-trending-news',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage stories']} actions={actions} component={TrendingNewsLayout} />)
                },
                {
                    path: 'trending-news',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage stories']} actions={actions} component={TrendingNewsListLayout} />)
                },
                {
                    path: 'manage-promotions',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage promotions']} actions={actions} component={PromotionApprovalsLayout} />)
                },
                {
                    path: 'add-partner',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage partners']} actions={actions} component={AddPartnerLayout} />)
                },
                {
                    path: 'manage-partners',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage partners']} actions={actions} component={PartnerApprovalsLayout} />)
                },
                {
                    path: 'add-package',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage packages']} actions={actions} component={AddPackageLayout} />)
                },
                {
                    path: 'manage-packages',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage packages']} actions={actions} component={PackageListLayout} />)
                },
                {
                    path: 'add-faq',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage Faqs']} actions={actions} component={AddFaqLayout} />)
                },
                {
                    path: 'manage-faqs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['Manage Faqs']} actions={actions} component={FaqListLayout} />)
                },
                {
                    path: 'overview',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={['User Management']} actions={actions} component={OverviewLayout} />)
                },
            ]
        },
    ]
)