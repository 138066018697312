import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!window.location.href.includes('/auth/login') && !window.location.href.includes('/auth/signup')) {
        if (config?.headers['requireLoader'] || !window.location.href.includes('/home')) {
          document.body.classList.add('loading-indicator');
        }
        const token = TokenService.getLocalAccessToken();
        console.log(token);
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
      }
      return config;
    },
    (error) => {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      document.body.classList.remove('loading-indicator');
      return res;
    },
    async (err) => {
      document.body.classList.remove('loading-indicator');
      console.log(err);
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          // localStorage.clear();
          // window.location.href = '/home'
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;