import React, { useState, useCallback, useEffect } from 'react'
import { FiBell, FiBriefcase, FiHome, FiLogOut, FiMessageSquare, FiSearch, FiUser, FiUsers } from 'react-icons/fi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../../../Services/user.service';
import './Header.scss'
import _debounce from 'lodash/debounce';
// import { Badge } from 'primereact/badge';
import { useDispatch, useSelector } from 'react-redux';
// import { FaUserCircle } from 'react-icons/fa'
import UserAvatar from '../../../../Components/UserAvatar/UserAvatar';
import { bindPofileImg } from '../../../../Store/features/user.slice';
import CorporateAvatar from '../../../../Components/CorporateAvatar/CorporateAvatar';

const menuItems = [
    {
        label: 'Home',
        icon: (<img src={'/assets/img/home.png'} alt='homeicon' />),
        active: (<img src={'/assets/img/home-a.png'} alt='homeicon' />),
        url: '/clubhouse/home',
        isPublic: true
    },
    {
        label: 'My Network',
        icon: (<img src={'/assets/img/network.png'} alt='networkicon' />),
        active: (<img src={'/assets/img/network-a.png'} alt='networkicon' />),
        url: '/clubhouse/connections',
        isPublic: false,
        isCorporate: false
    },
    {
        label: 'Jobs',
        icon: (<img src={'/assets/img/jobs.png'} alt='jobsicon' />),
        active: (<img src={'/assets/img/jobs-a.png'} alt='jobsicons' />),
        url: '/clubhouse/jobs',
        isPublic: false,
        isCorporate: false
    },
    {
        label: 'Jobs',
        icon: (<img src={'/assets/img/jobs.png'} alt='jobsicon' />),
        active: (<img src={'/assets/img/jobs-a.png'} alt='jobsicon' />),
        url: '/clubhouse/my-jobs',
        isPublic: false,
        isCorporate: true
    },
    // {
    // label: 'Applied Jobs',
    // icon: (<FiUser style={{ fontSize: 22 }} />),
    // url: '/clubhouse/applied-jobs',
    // },
    {
        label: 'Notifications',
        icon: (<img src={'/assets/img/nots.png'} alt='notifications' />),
        active: (<img src={'/assets/img/nots-a.png'} alt='notifications' />),
        url: '/clubhouse/notifications',
        isPublic: true,
    },
    {
        label: 'Messages',
        icon: (<img src={'/assets/img/messages.png'} alt='Messageicon' />),
        active: (<img src={'/assets/img/messages-a.png'} alt='messageicon' />),
        url: '/clubhouse/messages',
        isPublic: true,
    },
];


const Header = () => {

    const history = useNavigate();
    const location = useLocation();
    const logout = async () => {
        try {
            localStorage.clear();
            history('/home');
        } catch (error) {
            
        }
    }
    const [info, setInfo] = useState(null);
    const [showSuggestions, setToShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchInput, setsearchInput] = useState('');
    const [noResults, setNoResults] = useState(false);
    const [actions, setActions] = useState(false);
    const [notsCount, setNotsCount] = useState('');
    const loggedUser = useSelector(state => state?.user?.id);
    const userActions = useSelector(state => state?.user?.actions || []);
    const profileImg = useSelector(state => state?.user?.profile_img ?? null);
    const [menu, setMenu] = useState([])
    const [mobileImage, setMobileImage] = useState('/assets/img/mobile-icon.png');
    const [desktopImage, setDesktopImage] = useState('/assets/img/desktop-icon.png');

    useEffect(() => {
        getNots()
        getInfo();
    }, [])

    const disptach = useDispatch();

    useEffect(() => {
        setMenu(menuItems?.filter((a) => a?.isPublic || (!!a?.isCorporate === userActions?.includes('Corporate view'))))
    }, [userActions])

    const getInfo = async () => {
        try {
            const res = await UserService.getCurrentCandidate();
            disptach(bindPofileImg(res?.data?.profile_img ?? null));
            setInfo(res?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getNots = async () => {
        try {
            const res = await UserService.getNots();
            setNotsCount((res?.data ?? [])?.filter(q => !q?.read)?.length);
        } catch (error) {
            console.log(error)
        }
    }

    const getSuggestions = async (value) => {
        setNoResults(false)
        try {
            const res = await UserService.searchSuggestions(value);
            setSuggestions(res?.data || []);
            if (res?.data && !res?.data?.length) {
                setNoResults(true)
            } else {
                setNoResults(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const debounceFn = useCallback(_debounce(getSuggestions, 1000), []);

    const handleKeyPress = (event) => {
        setSuggestions([]);
        if (event.target.value) {
            debounceFn(event.target.value);
            setToShowSuggestions(true);
        } else {
            setToShowSuggestions(false);
        }

        // Trigger search on "Enter" key press or if on a mobile device
        if (event.key === 'Enter' || isMobile) {
            setSuggestions([]);
            setToShowSuggestions(false);
            history(`/clubhouse/search?value=${event.target.value}`);
        }
    };






    const isMobile = window.innerWidth <= 768;
    const faviconImage = isMobile ? (
        <img src='/assets/img/favicon.png' alt='MobileLogo' className='Mb2' style={{ cursor: 'pointer', height: 35 }} onClick={() => history('/clubhouse')} />
    ) : null;






    return (
        <div
            className='c-header d-flex align-items-center justify-content-between'
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 10,
            }}
        >
            <img alt='' src="/assets/img/logo.png" style={{ cursor: 'pointer', height: 35, }} onClick={() => history('/clubhouse')} className='Mb' />

            <img src='/assets/img/favicon.png' alt='MobileLogo' className='Mb2' style={{ cursor: 'pointer', height: 35, }} onClick={() => history('/clubhouse')} />

            <div className='d-flex justify-content-end IconsLeft'>
                {
                    menu.length > 0 && menu.map(m => (
                        <NavLink activeClassName='is-active-link' to={m?.label === 'Profile' ? `/clubhouse/candidate/${loggedUser}` : m?.url} key={m?.label}>
                            <div className='c-nav-link d-flex justify-content-center flex-column align-items-center'>
                                <p style={{ marginBottom: 0 }}>
                                    {/* {
m?.label === 'Notifications' ? (
<i className="pi pi-bell p-text-secondary p-overlay-badge" style={{ fontSize: 22, color: '#000' }}><Badge size='small' value={notsCount} ></Badge></i>
) : ( */}
                                    {
                                        location?.pathname?.includes(m?.url) ? m?.active : m?.icon
                                    }

                                    {/* )
} */}
                                </p>
                                <p className='Mb' style={{ marginBottom: 0, color: location?.pathname?.includes(m?.url) ? '#6480EC' : '#7C7C7C', fontSize: 14, fontWeight: 500 }}>
                                    {m?.label}
                                </p>
                            </div>

                        </NavLink>
                    ))
                }
            </div>
            <div style={{ display: 'flex' }}>
                <div className='Searchbar' style={{ width: 200, position: 'relative' }}>
                    <div>
                        <div className="dropdown">
                            <div className='d-flex align-items-center ' >
                                <input type="text" className="form-control"
                                    value={searchInput}
                                    onKeyUp={handleKeyPress}
                                    onChange={(event) => setsearchInput(event.target.value)}
                                    style={{
                                        paddingRight: '28px',
                                        borderRadius: 20,
                                        backgroundColor: '#F3F5F7',
                                    }} placeholder="Search Thaliva" />

                                <FiSearch style={{ fontSize: 18, cursor: 'pointer', position: 'absolute', right: '12px', top: '11px' }}

                                    color='#828282' />

                            </div>
                            {/* <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

<FiMoreVertical />
</a> */}
                            {
                                showSuggestions && (
                                    <div className="dropdown-menu dropdown-menu-end d-block" style={{ width: 350, right: 0 }}>
                                        {
                                            noResults ? (
                                                <p style={{ textAlign: 'center', marginBottom: 0 }}>
                                                    <b > No Results found</b>
                                                </p>
                                            ) : (<>
                                                {
                                                    suggestions.length > 0 && suggestions.map(s => (
                                                        <a href=' ' style={{ cursor: 'pointer', color: '#000' }} onClick={() => {
                                                            setsearchInput('')
                                                            setSuggestions([]);
                                                            setToShowSuggestions(false)
                                                            s?.type == 'user' ? history(`/clubhouse/candidate/${s?._id}`) : history(`/clubhouse/company/${s?._id}`);
                                                        }} className="dropdown-item">
                                                            <span style={{ fontWeight: 600, marginRight: 5 }}>{s?.name} </span> <span style={{ color: '#888' }}> - {s?.type === 'user' ? s?.currentDesignation : 'Company'}</span>
                                                        </a>
                                                    ))
                                                }
                                            </>)
                                        }

                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="dropdown" style={{ marginLeft: 7 }}>
                    <div className='d-flex align-items-center' style={{


                    }}>
                        <div
                            onClick={() => setActions(!actions)}
                            className="avatar me-3" style={{
                                width: 44, height: 44,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}>
                            {/* <span className="avatar-text bg-primary"><</span> */}
                            {
                                !!(profileImg) ? (
                                    <img alt='' className='img-fluid' style={{
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        width: 44, height: 44
                                    }} src={profileImg} />
                                ) : (
                                    userActions.includes('Corporate view') ? <CorporateAvatar size={60} /> : <UserAvatar name={info?.name} size={44} />
                                )
                            }
                        </div>

                    </div>
                    {/* <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

<FiMoreVertical />
</a> */}
                    {
                        actions && (
                            <div className="dropdown-menu dropdown-menu-end d-block" style={{
                                padding: 20,
                                borderRadius: 12, top: 50, right: 0
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div
                                        className="avatar me-3" style={{
                                            width: 44, height: 44,
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}>
                                        {/* <span className="avatar-text bg-primary"><</span> */}
                                        {
                                            !!(info?.profile_img) ? (
                                                <img alt='' className='img-fluid' style={{
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                    width: 44, height: 44
                                                }} src={typeof info?.profile_img === 'object' ? info?.profile_img?.value : info?.profile_img} />
                                            ) : (
                                               userActions.includes('Corporate view') ? <CorporateAvatar size={60} /> : <UserAvatar name={info?.name} size={44} />
                                            )
                                        }
                                    </div>
                                    <div>
                                        <p style={{
                                            color: '#1E1E1E',
                                            fontSize: 15,
                                            fontWeight: 700,
                                            marginBottom: 2
                                        }}>
                                            {userActions.includes('Corporate view') ? info?.corporate?.companyName : info?.name}
                                        </p>
                                        <p style={{
                                            color: '#1E1E1E',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            marginBottom: 0
                                        }}>
                                            {userActions.includes('Corporate view') ? info?.industry : info?.designation}
                                        </p>
                                    </div>

                                </div>
                                <div style={{
                                    paddingBottom: 20,
                                    marginBottom: 20,
                                    borderBottom: '1px solid #F0F0F0'
                                }}>

                                    <button style={{
                                        marginTop: 20,
                                        backgroundColor: '#fff',
                                        borderRadius: 20,
                                        padding: '8px 60px',
                                        width: 'max-content',
                                        border: '1px solid #6480EC',
                                        color: '#6480EC',
                                        fontSize: 14,
                                        fontWeight: 600,
                                    }} className='btn' onClick={() => {
                                        setActions(false)
                                        history(userActions.includes('Corporate view') ? `/clubhouse/company/${loggedUser}` : `/clubhouse/candidate/${loggedUser}`)
                                    }}>
                                        View Profile
                                    </button>
                                </div>
                                <p style={{
                                    color: '#1E1E1E',
                                    fontSize: 16,
                                    fontWeight: 600,

                                }}>
                                    Account
                                </p>
                                <p style={{
                                    color: '#7C7C7C',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    marginBottom: 6,
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setActions(false)
                                    history('/clubhouse/saved-items')
                                }}>
                                    Saved items
                                </p>
                                <p style={{
                                    color: '#7C7C7C',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    marginBottom: 6,
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setActions(false)
                                    history('/clubhouse/my-posts')
                                }}>
                                    My Posts
                                </p>
                                <p style={{
                                    color: '#7C7C7C',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginBottom: 6,
                                    cursor: 'pointer'

                                }}
                                    onClick={() => {
                                        setActions(false)
                                        history('/clubhouse/privacy')
                                    }}

                                >
                                    Privacy Policy
                                </p>
                                <p style={{
                                    color: '#7C7C7C',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginBottom: 6,
                                    cursor: 'pointer'
                                }}

                                    onClick={() => {
                                        setActions(false)
                                        history('/clubhouse/terms')
                                    }}


                                >



                                    Terms & Conditions
                                </p>
                                <p style={{
                                    color: '#7C7C7C',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginBottom: 0,
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setActions(false)
                                    logout()
                                }}>
                                    Logout
                                </p>
                            </div>
                        )
                    }
                </div>
                {/* <div className='c-nav-link d-flex justify-content-center flex-column align-items-center'
onClick={() => logout()}>
<p style={{ marginBottom: 5 }}>
<FiLogOut style={{ fontSize: 22 }} color="#000" />
</p>
<p style={{ marginBottom: 0, color:'#000' }}>
Logout
</p>
</div> */}
            </div>
        </div >
    )
}

export default Header