import api from './api';

const UserService = {

    sessionInfo(data) {
        return api.post("/user/session", data);
    },
    endSession(data) {
        return api.post("/user/end-session", data);
    },
    chartAnalytics(data) {
        return api.get("/user/analytics", data);
    },
    getUserinfo() {
        return api.get("/user/userinfo");
    },
    logout() {
        return api.get("/logout");
    },
    getJobs() {
        return api.get("/user/get-jobs");
    },
    getConnections() {
        return api.get("/user/get-connections");
    },
    uploadFiles(val) {
        return api.post("/user/upload-files", val, { headers: { 'content-type': 'multipart/form-data' } });
    },
    getRecomendedConnections() {
        return api.get("/user/get-recommended-connections");
    },
    getRecomendedCompanies() {
        return api.get("/user/get-recommended-companies");
    },
    getUserProfile() {
        return api.get("/user/get-user-profile");
    },
    getCorporateProfile() {
        return api.get("/user/get-corporate-profile");
    },
    updateProfileViews(id) {
        return api.get(`/user/update-profile-views/${id}`);
    },
    editUserProfile(data) {
        return api.put("/user/edit-user-profile", data);
    },
    editExperience(data) {
        return api.put("/user/edit-experience", data);
    },
    editProfileExperience(data) {
        return api.put("/user/edit-experience", data, { headers: { 'content-type': 'multipart/form-data' } });
    },
    editCorporateExperience(data) {
        return api.put("/user/edit-corporate-experience", data, { headers: { 'content-type': 'multipart/form-data' } });
    },
    editCorporateProfile(data) {
        return api.put("/user/edit-corporate-profile", data);
    },
    updatePoll(data) {
        return api.post("/user/update-poll", data, { headers: { 'requireLoader': true } });
    },
    getALLJobs() {
        return api.get("/user/get-all-jobs");
    },
    createJob(data) {
        return api.post("/user/create-job", data);
    },
    createEvent(data) {
        return api.post("/user/create-event", data);
    },
    createPoll(data) {
        return api.post("/user/create-poll", data);
    },
    getEvents() {
        return api.get("/user/get-events");
    },
    getPromotions() {
        return api.get("/user/get-promotions");
    },
    getJobById(id) {
        return api.get(`/user/get-job/${id}`);
    },
    editJob(data) {
        return api.put("/user/edit-job", data);
    },
    getCompanyById(id) {
        return api.get(`/user/get-company/${id}`);
    },
    getCandidateById(id) {
        return api.get(`/user/get-candidate/${id}`);
    },
    getPosts(page) {
        return api.get(`/user/get-posts?page=${page}`);
    },
    getTrending() {
        return api.get("/admin/get-storys");
    },
    getPostById(id) {
        return api.get(`/user/get-post/${id}`);
    },
    createPost(data) {
        return api.post("/user/create-post", data, { headers: { 'content-type': 'multipart/form-data' } });
    },
    createLike(data) {
        return api.post("/user/create-like", data);
    },
    createShare(data) {
        return api.post("/user/create-share", data);
    },
    createComment(data) {
        return api.post("/user/create-comment", data);
    },
    createSavedItem(data) {
        return api.post("/user/create-saved-item", data);
    },
    hideItem(data) {
        return api.post("/user/hide-item", data);
    },
    createReport(data) {
        return api.post("/user/create-report", data);
    },
    removeSavedItem(id) {
        return api.delete(`/user/remove-saved-item/${id}`);
    },
    getSavedItems() {
        return api.get("/user/saved-items");
    },
    myPosts() {
        return api.get("/user/my-posts");
    },
    getCurrentCandidate() {
        return api.get(`/user/get-current-candidate`);
    },
    getApprovedCorporates() {
        return api.get(`/user/get-corporates`);
    },
    addFriend(data) {
        return api.post("/user/add-friend", data);
    },
    follow(data) {
        return api.post("/user/follow-company", data);
    },
    unfollow(data) {
        return api.post("/user/unfollow-company", data);
    },
    isFollowing(data) {
        return api.post("/user/is-following", data);
    },
    unfriend(data) {
        return api.post("/user/unfriend", data);
    },
    acceptFriend(data) {
        return api.post("/user/accept-friend", data);
    },
    rejectFriend(data) {
        return api.post("/user/reject-friend", data);
    },
    withdrawRequest(data) {
        return api.post("/user/withdraw-request", data);
    },
    checkIsFriend(data) {
        return api.post("/user/check-is-friend", data);
    },

    acceptCandidate(data) {
        return api.post("/user/accept-candidate", data);
    },
    applyJob(data) {
        return api.post("/user/apply-job", data);
    },
    saveJob(data) {
        return api.post("/user/save-job", data);
    },
    closeJob(data) {
        return api.post("/user/close-job", data);
    },
    rejectCandidate(data) {
        return api.post("/user/reject-candidate", data);
    },
    checkIsApplied(id) {
        return api.get(`/user/check-is-applied/${id}`);
    },
    withdrawJobRequest(data) {
        return api.post("/user/withdraw-job-request", data);
    },
    getJobInfoByCorporate(id) {
        return api.get(`/user/get-job-info-by-corporate/${id}`);
    },

    getAppliedjobsByCandidate() {
        return api.get(`/user/get-applied-jobs-by-candidate`);
    },

    getSavedjobsByCandidate() {
        return api.get(`/user/get-saved-jobs-by-candidate`);
    },
    getConvs() {
        return api.get(`/user/get-convs`);
    },
    seachValue(value) {
        return api.get(`/user/search-connections/${value}`);
    },
    seachJobs(value) {
        return api.get(`/user/search-jobs/${value}`);
    },
    seachValueForCorporate(value) {
        return api.get(`/user/search-connections-for-corporate/${value}`);
    },
    searchSuggestions(value) {
        return api.get(`/user/search-suggestions/${value}`);
    },
    getNots() {
        return api.get(`/user/get-nots`);
    },
    readNots(id) {
        return api.get(`/user/read-nots/${id}`);
    },
    getReferralCode() {
        return api.get(`/user/get-referral-code`);
    },

}

export default UserService;