import React from 'react'
import LoadingGif from './loading.gif'
import './NavLoader.scss'

const NavLoader = () => {
  return (
    <div className='navLoader-container'>
        <img src={LoadingGif} alt="loading..." />
    </div>
  )
}

export default NavLoader